import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  // ------------------ News carousel
  const newsCarousels = document.querySelectorAll('.news__carrousel');

  if (0 < newsCarousels.length) {
    newsCarousels.forEach(function (newsCarousel) {
      const buttonsDesktop = newsCarousel.querySelector(
        '.opcq__swiper__buttons--desktop'
      );

      const buttonsMobile = newsCarousel.querySelector(
        '.opcq__swiper__buttons--mobile'
      );
      const swiperConfig = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 32,
        freeMode: true,
        draggable: true,
        keyboard: {
          enabled: true,
        },
        breakpoints: {
          320: {
            navigation: {
              nextEl: buttonsMobile.querySelector('.swiper-button-next'),
              prevEl: buttonsMobile.querySelector('.swiper-button-prev'),
            },
          },
          1200: {
            navigation: {
              nextEl: buttonsDesktop.querySelector('.swiper-button-next'),
              prevEl: buttonsDesktop.querySelector('.swiper-button-prev'),
            },
          },
        },
        navigation: {
          nextEl: newsCarousel.querySelector('.swiper-button-next'),
          prevEl: newsCarousel.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: newsCarousel.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
      };

      const newSwiper = () => {
        new Swiper(newsCarousel.querySelector('.news-swiper'), swiperConfig);
      };

      newSwiper();

      const timeoutWindowResize = (myFunc) => {
        let timer;
        return (e) => {
          if (timer) clearTimeout(timer);
          timer = setTimeout(myFunc, 300, e);
        };
      };

      window.addEventListener(
        'resize',
        timeoutWindowResize(() => {
          newSwiper();
        })
      );
    });
  }
});
